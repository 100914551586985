/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Col, Divider, Form, Input, message, Modal, Row, Tooltip } from 'antd';

import { CopyOutlined, SearchOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';

import '../../TaskList/Components/ModalStyle.scss';

function ColumnsSettingsModal({ columns, setIsVisible }) {
  const [searchText, setSearchText] = useState('');

  // Carrega as colunas disponíveis
  let columnsArray = columns({
    editingIds: null,
    fieldsPermissions: null,
    columnsSettings: null,
    returnAllColumns: true,
    instructorOptions: null,
    driverOptions: null,
    vehicleOptions: null,
    reasonOptions: null,
    transportCompanyOptions: null,
    trainingCenterOptions: null,
  });

  // Ordena as colunas pelo título em ordem alfabética
  columnsArray = columnsArray.sort((a, b) => a.title.localeCompare(b.title));

  const searchFilter = ({ title }) => {
    const titleLower = title.toLowerCase();

    if (searchText !== '') {
      return titleLower.includes(searchText);
    }
    return titleLower;
  };

  const handleSearch = (element) => {
    const text = element.target.value;
    const textLower = text.toLowerCase();

    setSearchText(textLower);
  };

  return (
    <Modal
      title="Mapeamento de Campos do Treinamento"
      open={true}
      width="70%"
      className="training-list-columns-settings-modal"
      onCancel={() => setIsVisible(false)}
      footer={[]}
    >
      <Row gutter={[24]} style={{ width: '100%' }}>
        <Col span={8}>
          <Form.Item key="search">
            <Input
              placeholder="Econtrar campo"
              onKeyUp={handleSearch}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{ borderTopColor: '#91919173' }} orientation="left" />

      <Row gutter={[24]}>
        {columnsArray.filter(searchFilter).map((column) => (
          <Col span={12}>
            <div style={{ display: 'flex', gap: 4 }}>
              <Tooltip title="Copiar">
                <CopyOutlined
                  onClick={() => {
                    navigator.clipboard
                      .writeText(column.dataIndex)
                      .then(() => {
                        message.info('Copiado para área de transferência!');
                      })
                      .catch((err) => {
                        Utils.logError(err);
                        message.error('Oops. Algo deu errado ao copiar o campo!');
                      });
                  }}
                />
              </Tooltip>
              <strong>{column.title}</strong>
              <span>{'=>'}</span>
              <i>{column.dataIndex}</i>
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default ColumnsSettingsModal;
