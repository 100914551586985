/* eslint-disable prettier/prettier */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Popconfirm, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

function ProductRegister() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id: editId } = useParams();

  const [loading, setLoading] = useState(false);
  const [screenModified, setScreenModified] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();

  const fetchProductEdit = async (id) => {
    setLoading(true);

    const res = await api
      .get(`/Product?id=${id}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados!');
      });

    form.setFieldsValue(res);
    setLoading(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    await api
      .put('/Product', values)
      .then(() => {
        message.success(`Dados atualizados!`);
        form.resetFields();

        if (editId) {
          fetchProductEdit(editId);
        }

        setLoading(false);
        setScreenModified(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Erro ao enviar os dados do Produto!');
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'Product') {
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  useEffect(() => {
    if (editId) {
      fetchProductEdit(editId);
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      onChange={() => setScreenModified(true)}
      style={{ maxWidth: '100%', margin: '0 auto' }}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Button loading={loading} block type="primary" htmlType="submit">
          Salvar
        </Button>
      </div>
      {/* Campos invisiveis */}
      <Form.Item className="input-hidden" label="Id" name="id">
        <Input />
      </Form.Item>
      <Form.Item className="input-hidden" label="IdBitrix" name="idBitrixProduct">
        <Input />
      </Form.Item>

      <Row gutter={[24]}>
        {fieldsPermissions?.Name.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Produto"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
        {fieldsPermissions?.Sayings.access !== 0 && (
          <Col span={7}>
            <Form.Item
              label="Dizeres"
              name="sayings"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Workload.access !== 0 && (
          <Col span={7}>
            <Form.Item
              label="Carga Horária"
              name="workload"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Description.access !== 0 && (
          <Col span={8}>
            <Form.Item
              label="Descrição"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Digite a descrição aqui..." />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.ProgramContentList.access !== 0 && (
          <Col span={24}>
            <Form.List name="programContentList">
              {(fields, { add, remove }) => (
                <>
                  <Button
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                      width: 400,
                    }}
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Conteúdo Programático
                  </Button>

                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={[24]} key={key}>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'module']}
                          rules={[{ required: true, message: 'Campo obrigatório!' }]}
                        >
                          <Input placeholder="Módulo" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'subject']}
                          rules={[{ required: true, message: 'Campo obrigatório!' }]}
                        >
                          <Input placeholder="Assunto" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, 'theoreticalGoals']}
                          rules={[{ required: true, message: 'Campo obrigatório!' }]}
                        >
                          <Input placeholder="Objetivos Teóricos" />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'practicalGoals']}
                          rules={[{ required: true, message: 'Campo obrigatório!' }]}
                        >
                          <Input placeholder="Objetivos Práticos" />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <DeleteOutlined
                          style={{ color: 'red', float: 'right' }}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default ProductRegister;
