import React, { useLayoutEffect, useState } from 'react';
import { Col, message, Row, Spin } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';

import Utils from './Assets/Scripts/Utils';
import BaseLayout from './Components/BaseLayout/BaseLayout';
import AppDownload from './Pages/App/AppDownload/AppDownload';
import AppPrivacyPolicy from './Pages/App/AppPrivacyPolicy/AppPrivacyPolicy';
import AppSupportInfo from './Pages/App/AppSupportInfo/AppSupportInfo';
import Billing from './Pages/Billing/Billing';
import CertificateView from './Pages/Certificate/CertificateView';
import CompanyList from './Pages/Company/List/CompanyList';
import CompanyRegister from './Pages/Company/Register/CompanyRegister';
import DriverList from './Pages/Driver/List/DriverList';
import DriverRegister from './Pages/Driver/Register/DriverRegister';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Home from './Pages/Home/Home';
import InstructorList from './Pages/Instructor/List/InstructorList';
import InstructorRegister from './Pages/Instructor/Register/InstructorRegister';
import InstructorManagement from './Pages/InstructorManagement/InstructorManagement';
import InstructorPreRegisterList from './Pages/InstructorPreRegister/List/InstructorPreRegisterList';
import InstructorPreRegister from './Pages/InstructorPreRegister/Register/InstructorPreRegister';
import KnowledgeBase from './Pages/KnowledgeBase/KnowledgeBase';
import Login from './Pages/Login/Login';
import ProductList from './Pages/Product/List/ProductList';
import ProductRegister from './Pages/Product/Register/ProductRegister';
import Profile from './Pages/Profile/Profile';
import RoleList from './Pages/Role/List/RoleList';
import RoleRegister from './Pages/Role/Register/RoleRegister';
import ScheduleOverview from './Pages/ScheduleConecta/ScheduleOverview';
import ScheduleScheduling from './Pages/ScheduleConecta/ScheduleScheduling';
import ScheduleDriver from './Pages/ScheduleDriver/ScheduleDriver';
import ScheduleInstructor from './Pages/ScheduleInstructor/ScheduleInstructor';
import ScheduleTrainingCenter from './Pages/ScheduleTrainingCenter/ScheduleTrainingCenter';
import Settings from './Pages/Settings/Settings';
import TaskListView from './Pages/TaskList/TaskListView';
import TrainingCenterList from './Pages/TrainingCenter/List/TrainingCenterList';
import TrainingCenterRegister from './Pages/TrainingCenter/Register/TrainingCenterRegister';
import TransportList from './Pages/Transport/List/TransportList';
import TransportRegister from './Pages/Transport/Register/TransportRegister';
import UserList from './Pages/User/List/UserList';
import UserRegister from './Pages/User/Register/UserRegister';
import VehicleList from './Pages/Vehicle/List/VehicleList';
import VehicleRegister from './Pages/Vehicle/Register/VehicleRegister';
import { api } from './Services/axiosService';

export default function NavigationRoutes() {
  const [permissions, setPermissions] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUserAndPermissions = async () => {
    setLoading(true);
    const userData = localStorage.getItem('conecta__userData');

    if (userData) {
      const userId = JSON.parse(userData).id;

      let newUserData;
      let newRoleData;
      try {
        const userResponse = await api.get(`/User/UserAndRole?userId=${userId}`);
        newUserData = userResponse.data.userData;
        newRoleData = userResponse.data.roleData;
      } catch (error) {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados do Usuário e Cargo!');
      }

      if (newUserData) {
        const parsedUserData = {
          id: newUserData.id,
          name: newUserData.name,
          login: newUserData.login,
          email: newUserData.email,
          phone1: newUserData.phone1,
          phone2: newUserData.phone2,
          profilePic: newUserData.profilePic,
          firstAccess: newUserData.firstAccess,
          showNewWebVersion: newUserData.showNewWebVersion,
          companyId: newUserData.company?.id,
          companyName: newUserData.company?.name,
          notificationToken: newUserData.notificationToken,
          notificationTopic: newUserData.notificationTopic,
          companyType: newUserData.company?.companyType,
          expoNotificationToken: newUserData.expoNotificationToken,
          isApprover: newUserData.isApprover,
          bitrixId: newUserData.bitrixId,
        };

        localStorage.setItem('conecta__userData', JSON.stringify(parsedUserData));
      }

      if (newRoleData) {
        localStorage.setItem('conecta__permissions', JSON.stringify(newRoleData));

        const permissions = {};
        newRoleData.resources.forEach(({ name, canView, canHandle, selfView }) => {
          permissions[name] = { canView, canHandle, selfView };
        });
        setPermissions(permissions);
      }
    }

    setLoading(false);
  };

  useLayoutEffect(() => {
    fetchUserAndPermissions();
  }, []);

  if (loading) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/Instrutor" element={<InstructorPreRegister />} />
        <Route exact path="/InstrutorMobile" element={<InstructorPreRegister mobile={true} />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/RecuperarSenha" element={<ForgotPassword />} />
        <Route exact path="/App/Download" element={<AppDownload />} />
        <Route exact path="/App/PoliticaPrivacidade" element={<AppPrivacyPolicy />} />
        <Route exact path="/App/Suporte" element={<AppSupportInfo />} />
        <Route exact path="/RecuperarSenhaMobile" element={<ForgotPassword mobile={true} />} />
        <Route path="/BaseConhecimento/:authToken" element={<KnowledgeBase />} />
        <Route path="/BaseConhecimento/:authToken/:currentPath" element={<KnowledgeBase />} />
        {Object.keys(permissions ?? {}).length === 0 && <Route path="*" element={<Login />} />}
        {Object.keys(permissions ?? {}).length > 0 && (
          <Route path="/" element={<BaseLayout />}>
            <Route exact path="/" element={<Home />} />

            {permissions.Configuration?.canView && (
              <Route exact path="/Configuracao" element={<Settings />} />
            )}

            {permissions.PreInstructor?.canView && (
              <Route exact path="/PreCadastroInstrutor" element={<InstructorPreRegisterList />} />
            )}

            {permissions.PreInstructor?.canHandle && (
              <Route
                exact
                path="/PreCadastroInstrutor/Editar/:id"
                element={<InstructorPreRegister />}
              />
            )}

            {permissions.Profile?.canView && <Route exact path="/Perfil" element={<Profile />} />}

            {permissions.User?.canView && (
              <Route exact path="/Usuario/Listar" element={<UserList />} />
            )}

            {permissions.User?.canHandle && (
              <Route exact path="/Usuario/Cadastrar" element={<UserRegister />} />
            )}

            {permissions.User?.canHandle && (
              <Route exact path="/Usuario/Editar/:id" element={<UserRegister />} />
            )}

            {permissions.Role?.canView && (
              <Route exact path="/Cargo/Listar" element={<RoleList />} />
            )}

            {permissions.Role?.canHandle && (
              <Route exact path="/Cargo/Cadastrar" element={<RoleRegister />} />
            )}

            {permissions.Role?.canHandle && (
              <Route exact path="/Cargo/Editar/:id" element={<RoleRegister />} />
            )}

            {permissions.TrainingCenter?.canView && (
              <Route exact path="/CentroTreinamento/Listar" element={<TrainingCenterList />} />
            )}

            {permissions.TrainingCenter?.canHandle && (
              <Route
                exact
                path="/CentroTreinamento/Cadastrar"
                element={<TrainingCenterRegister />}
              />
            )}

            {(permissions.TrainingCenter?.canHandle || permissions.TrainingCenter?.selfView) && (
              <Route
                exact
                path="/CentroTreinamento/Editar/:id"
                element={<TrainingCenterRegister />}
              />
            )}

            {permissions.Instructor?.canView && (
              <Route exact path="/Instrutor/Listar" element={<InstructorList />} />
            )}

            {permissions.Instructor?.canHandle && (
              <Route exact path="/Instrutor/Cadastrar" element={<InstructorRegister />} />
            )}

            {(permissions.Instructor?.canHandle || permissions.Instructor?.selfView) && (
              <Route exact path="/Instrutor/Editar/:id" element={<InstructorRegister />} />
            )}

            {permissions.Driver?.canView && (
              <Route exact path="/Motorista/Listar" element={<DriverList />} />
            )}

            {permissions.Driver?.canHandle && (
              <Route exact path="/Motorista/Cadastrar" element={<DriverRegister />} />
            )}

            {(permissions.Driver?.canHandle || permissions.Driver?.selfView) && (
              <Route exact path="/Motorista/Editar/:id" element={<DriverRegister />} />
            )}

            {permissions.Vehicle?.canView && (
              <Route exact path="/Veiculo/Listar" element={<VehicleList />} />
            )}

            {permissions.Vehicle?.canHandle && (
              <Route exact path="/Veiculo/Cadastrar" element={<VehicleRegister />} />
            )}

            {(permissions.Vehicle?.canHandle || permissions.Vehicle?.selfView) && (
              <Route exact path="/Veiculo/Editar/:id" element={<VehicleRegister />} />
            )}

            {permissions.Transport?.canView && (
              <Route exact path="/Transporte/Listar" element={<TransportList />} />
            )}

            {permissions.Transport?.canHandle && (
              <Route exact path="/Transporte/Cadastrar" element={<TransportRegister />} />
            )}

            {(permissions.Transport?.canHandle || permissions.Transport?.selfView) && (
              <Route exact path="/Transporte/Editar/:id" element={<TransportRegister />} />
            )}

            {permissions.InstructorManagement?.canView && (
              <Route exact path="/GestaoInstrutores" element={<InstructorManagement />} />
            )}

            {permissions.InstructorManagement?.canView && (
              <Route
                exact
                path="/GestaoInstrutores/Agenda/Instrutor/:id"
                element={<ScheduleInstructor />}
              />
            )}

            {permissions.ScheduleOverview?.canView && (
              <Route exact path="/Agenda/VisaoGeral" element={<ScheduleOverview />} />
            )}

            {permissions.ScheduleScheduling?.canView && (
              <Route exact path="/Agenda/Agendamento" element={<ScheduleScheduling />} />
            )}

            {permissions.ScheduleScheduling?.canView && (
              <Route
                exact
                path="/Agenda/Agendamento/:action/:ctMaxCapacity/:ctName/:ctId"
                element={<ScheduleScheduling />}
              />
            )}

            {permissions.ScheduleScheduling?.canView && (
              <Route
                exact
                path="/Agenda/Agendamento/:action/:taskId"
                element={<ScheduleScheduling />}
              />
            )}

            {permissions.ScheduleTrainingCenter?.canView && (
              <Route exact path="/Agenda/CentroTreinamento" element={<ScheduleTrainingCenter />} />
            )}

            {permissions.ScheduleTrainingCenter?.canView && (
              <Route
                exact
                path="/Agenda/CentroTreinamento/:action/:taskId"
                element={<ScheduleTrainingCenter />}
              />
            )}

            {permissions.ScheduleInstructor?.canView && (
              <Route exact path="/Agenda/Instrutor" element={<ScheduleInstructor />} />
            )}

            {permissions.ScheduleDriver?.canView && (
              <Route exact path="/Agenda/Motorista" element={<ScheduleDriver />} />
            )}

            {/* {permissions.KnowledgeBase?.canView && (
              <Route path="/BaseConhecimento" element={<KnowledgeBase />} />
            )} */}

            {permissions.Billing?.canView && <Route path="/Faturamento" element={<Billing />} />}

            {permissions.TrainingList?.canView && (
              <Route exact path="Treinamentos/Listar" element={<TaskListView />} />
            )}

            {permissions.Certificates?.canView && (
              <Route exact path="/Certificados/Listar" element={<CertificateView />} />
            )}

            {permissions.Product?.canView && (
              <Route exact path="/Produtos/Listar" element={<ProductList />} />
            )}

            {permissions.Product?.canHandle && (
              <Route exact path="/Produtos/Editar/:id" element={<ProductRegister />} />
            )}

            {permissions.Company?.canView && (
              <Route exact path="/Empresas/Listar" element={<CompanyList />} />
            )}

            {permissions.Company?.canView && (
              <Route exact path="/Empresas/Cadastrar" element={<CompanyRegister />} />
            )}

            {permissions.Company?.canView && (
              <Route exact path="/Empresas/Editar/:id" element={<CompanyRegister />} />
            )}

            <Route path="*" element={<Home />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}
