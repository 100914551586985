import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import moment from 'moment';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  FilterOutlined,
  GroupOutlined,
  LoadingOutlined,
  SearchOutlined,
  SettingOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import TaskListColumns from '../TaskList/Shared/TaskListColumns';
import UserFunctions from '../User/Register/UserFunctions';

import {
  fetchProduct,
  fetchSettings,
  fetchStages,
  fetchTasks,
  fetchTrainingCenters,
  fetchTrainings,
} from './API/CertificateAPI';
import CertificateSettingsModal from './Components/CertificateSettingsModal';
import UploadStudentsModal from './Components/UploadStudentsModal';

import './CertificateStyle.scss';

const { RangePicker } = DatePicker;

function CertificateView() {
  const [formFilter] = Form.useForm();
  const todayDate = moment().add(-3, 'hour');

  // Modal
  const [isUploadStudentsModalOpen, setIsUploadStudentsModalOpen] = useState(false);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [isUploadStudentsTypeModal, setIsUploadStudentsTypeModal] = useState(false);

  // Geral
  const [loading, setLoading] = useState(false);
  const [filters, setfilters] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [subTableSelectedRowKeys, setSubTableSelectedRowKeys] = useState([]);

  // Options
  const [trainingCenterOptions, setTrainingCenterOptions] = useState([]);
  const [trainingOptions, setTrainingOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [bitrixUserOptions, setBitrixUserOptions] = useState([]);
  const [certificateModelOptions, setCertificateModelOptions] = useState([]);
  const [certificateProductOptions, setCertificateProductOptions] = useState([]);

  const handleFetchTasks = async (pFilters = filters) => {
    setLoading(true);
    setfilters(pFilters);
    const taskFilters = pFilters;

    if (pFilters?.period) {
      const [start, end] = pFilters.period;
      taskFilters.startDate = start;
      taskFilters.endDate = end;
    }

    const tasks = await fetchTasks(taskFilters);

    setTaskList(tasks);

    setLoading(false);
  };

  const fetchData = async () => {
    try {
      const [settings, trainingCenters, trainings, stages, product] = await Promise.all([
        fetchSettings(),
        fetchTrainingCenters(),
        fetchTrainings(),
        fetchStages(),
        fetchProduct(),
      ]);

      setTrainingCenterOptions(trainingCenters);
      setTrainingOptions(trainings);
      setStageOptions(stages);
      setCertificateProductOptions(product);

      await UserFunctions.fetchBitrixUsers(settings.bitrixWebhookUrl, setBitrixUserOptions);

      setCertificateModelOptions(settings.certificateModelList);

      if (process.env.NODE_ENV === 'production') {
        await handleFetchTasks({ period: [todayDate, todayDate] });
      } else {
        await handleFetchTasks({});
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as opções de filtro!');
    }
  };

  const handleGenerateCertificate = async () => {
    // Map selected tasks to the CertificateTableRowModel structure
    const certificateTableModel = selectedRowKeys
      .map((taskId) => {
        const task = taskList.find((t) => t.id === taskId);
        if (!task || !task.queryStudentList) return null;

        // Filter and collect student IDs for the current task
        const studentIds = task.queryStudentList
          .filter((student) => subTableSelectedRowKeys.includes(student.id))
          .map((student) => student.id);

        return {
          TaskId: taskId,
          StudentIdList: studentIds,
        };
      })
      .filter(Boolean); // Filter out any null entries

    if (certificateTableModel.length === 0) {
      message.warn('Oops. Nenhum registro válido para gerar certificado!');
      return;
    }

    const certificateEndpointModel = { CertificateTableRowList: certificateTableModel };

    try {
      const response = await fetch(`${process.env.REACT_APP_HOST}/Task/GenerateCertificate`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
          'Content-Type': 'application/json; charset=UTF-8',
          Accept: 'application/zip', // Accept header should expect a ZIP file format
        },
        body: JSON.stringify(certificateEndpointModel),
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error('Failed to generate certificates');
      }

      const blob = await response.blob();
      const fileName = `Certificates_${moment(new Date()).format('YYYY-MM-DD')}.zip`; // Correct filename and extension
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      message.success('Download do certificado realizado com sucesso!');
    } catch (error) {
      console.error('Error generating certificates:', error);
      message.error('Oops. Algo deu errado ao tentar baixar o certificado!');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.readyGenerateCertificate,
    }),
  };

  const handleOpenModal = (type) => {
    switch (type) {
      case 'uploadStudents':
        setIsUploadStudentsModalOpen(true);
        setIsUploadStudentsTypeModal('uploadStudents');
        break;
      case 'uploadCompany':
        setIsUploadStudentsModalOpen(true);
        setIsUploadStudentsTypeModal('uploadCompany');
        break;
      default:
        console.error('Tipo de modal desconhecido: ', type);
    }
  };

  const renderFilter = () => (
    <Form
      form={formFilter}
      disabled={loading}
      name="TaskListFilter"
      onFinish={handleFetchTasks}
      layout="vertical"
      autoComplete="off"
      initialValues={{
        period: process.env.NODE_ENV === 'production' ? [todayDate, todayDate] : [],
        responsibles: [],
        stages: [],
        trainingCenterIds: [],
        trainings: [],
        typeTrainings: [],
      }}
      style={{ width: '25vw', backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}
    >
      <Form.Item name="period" label="Período">
        <RangePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item label="Centro de Treinamento" name="trainingCenterIds">
        <Select
          options={trainingCenterOptions}
          loading={loading}
          placeholder="Selecione um CT"
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          showSearch
          allowClear
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(
              values,
              trainingCenterOptions,
              'trainingCenterIds',
              formFilter
            )
          }
        />
      </Form.Item>
      <Form.Item label="Possui Configuração de Certificado" name="readyGenerateCertificate">
        <Select
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16 }}
        />
      </Form.Item>

      <Form.Item label="Responsável" name="responsibles">
        <Select
          options={bitrixUserOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, bitrixUserOptions, 'responsibles', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Estágio" name="stages">
        <Select
          options={stageOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, stageOptions, 'stages', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Treinamento" name="trainings">
        <Select
          options={trainingOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, trainingOptions, 'trainings', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Tipo" name="typeTrainings">
        <Select
          options={[
            { label: 'CT', value: 'CT' },
            { label: 'In Company', value: 'In Company' },
            { label: 'EAD', value: 'EAD' },
          ]}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16 }}
          mode="multiple"
          maxTagCount={3}
        />
      </Form.Item>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Button type="primary" htmlType="submit" block>
            <SearchOutlined />
          </Button>
        </Col>
        <Col span={12}>
          <Button type="default" onClick={() => formFilter.resetFields()} block>
            Limpar Filtros
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const expandableConfig = {
    expandedRowRender: (record) => (
      <Table
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
          },
          {
            title: 'CPF',
            dataIndex: 'cpf',
          },
          {
            title: 'CNPJ',
            dataIndex: 'cnpj',
          },
          {
            title: 'Endereço',
            dataIndex: 'address',
          },
          {
            title: 'Razão Social',
            dataIndex: 'commercialName',
          },
        ]}
        dataSource={record.children}
        pagination={false}
        showHeader={true}
        rowSelection={{
          subTableSelectedRowKeys,
          onChange: (keys) => {
            setSubTableSelectedRowKeys(keys);
          },
          getCheckboxProps: (record) => ({
            disabled: !record.readyGenerateCertificate,
          }),
        }}
      />
    ),
    rowExpandable: (record) => record.children?.length > 0,
  };

  let certificateListColumns = [];

  if (fieldsPermissions) {
    certificateListColumns = TaskListColumns({
      editingIds: null,
      fieldsPermissions,
      columnsSettings: null,
      returnAllColumns: true,
      instructorOptions: null,
      driverOptions: null,
      vehicleOptions: null,
      reasonOptions: null,
      transportCompanyOptions: null,
      trainingCenterOptions,
      formTaskList: null,
      filterByPermissions: true,
    });
    certificateListColumns.unshift({
      width: 30,
      render: (_, record) => {
        if (!record.readyGenerateCertificate) {
          return (
            <Tooltip title="Não está pronto para gerar certificado" placement="left">
              <CloseCircleOutlined style={{ color: '#fc5d20', fontSize: 20 }} />
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Pronto para gerar certificado" placement="left">
            <CheckCircleOutlined style={{ color: '#3bb856', fontSize: 20 }} />
          </Tooltip>
        );
      },
    });
  }

  useEffect(() => {
    fetchData();

    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'Certificates') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );

    setFieldsPermissions(permissions);
  }, []);

  const shouldDisableCertificateGeneration = () => {
    // Check if no tasks or selected rows are available
    if (selectedRowKeys.length === 0 || taskList.length === 0) {
      return true;
    }

    // Filter tasks that are selected
    const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));

    // Check each selected task for the required properties
    return selectedTaskList.some(
      (task) => !task.certificateModel || !task.studentList || !task.queryStudentList
    );
  };

  if (loading || !fieldsPermissions) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!loading && fieldsPermissions) {
    return (
      <>
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            {/* Filtro */}
            <Tooltip title="Filtro">
              <Popover content={renderFilter} trigger="click" overlayClassName="custom-popover">
                <Button type="default" className="filterComponent" icon={<FilterOutlined />} />
              </Popover>
            </Tooltip>

            {/* Importar Lista de Alunos */}
            {fieldsPermissions?.ImportStudentListBtn?.access >= 1 && (
              <Tooltip title="Importar Lista de Alunos">
                <Button
                  icon={<SnippetsOutlined />}
                  onClick={() => handleOpenModal('uploadStudents')}
                  disabled={selectedRowKeys.length === 0}
                />
              </Tooltip>
            )}

            {/* Importar Dados de Empresa */}
            {fieldsPermissions?.ImportCompanyDataBtn?.access >= 1 && (
              <Tooltip title="Importar Dados de Empresa">
                <Button
                  icon={<GroupOutlined />}
                  onClick={() => handleOpenModal('uploadCompany')}
                  disabled={selectedRowKeys.length === 0}
                />
              </Tooltip>
            )}

            {/* Configuração Certificado no Treinamento */}
            {fieldsPermissions?.CertificateTrainingSettingsBtn?.access >= 1 && (
              <Tooltip title="Configuração Certificado no Treinamento">
                <Button
                  icon={<SettingOutlined />}
                  onClick={() => setIsCertificateModalOpen(true)}
                  disabled={selectedRowKeys.length === 0}
                />
              </Tooltip>
            )}

            {/* Gerar Certificado */}
            {fieldsPermissions?.GenerateCertificateBtn?.access >= 1 && (
              <Tooltip title="Gerar Certificado">
                <Button
                  icon={<FilePdfOutlined />}
                  onClick={() => handleGenerateCertificate()}
                  disabled={shouldDisableCertificateGeneration()}
                />
              </Tooltip>
            )}
          </div>
        </div>

        <Table
          columns={certificateListColumns}
          rowSelection={rowSelection}
          dataSource={taskList}
          scroll={{ y: '75vh' }}
          size="small"
          pagination={{
            position: ['bottomRight'],
            size: 'default',
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100, 200],
            showSizeChanger: true,
            total: taskList?.length ?? 0,
            showTotal: (total) => `${total} treinamentos`,
          }}
          className="certificate-table"
          expandable={{
            ...expandableConfig,
          }}
        />
        {isUploadStudentsModalOpen && (
          <UploadStudentsModal
            setIsOpen={setIsUploadStudentsModalOpen}
            taskList={taskList}
            selectedRowKeys={selectedRowKeys}
            isUploadStudentsTypeModal={isUploadStudentsTypeModal}
          />
        )}
        {isCertificateModalOpen && (
          <CertificateSettingsModal
            isCertificateModalOpen={isCertificateModalOpen}
            setIsOpen={setIsCertificateModalOpen}
            certificateModelOptions={certificateModelOptions}
            certificateProductOptions={certificateProductOptions}
            taskList={taskList}
            selectedRowKeys={selectedRowKeys}
          />
        )}
      </>
    );
  }
}

export default CertificateView;
