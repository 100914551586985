/* eslint-disable prettier/prettier */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Popconfirm, Row, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Inputmask from 'inputmask';
import { useNavigate, useParams } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';
import { uploadProfilePic } from '../../../Services/firebaseService';

import UserFunctions from './UserFunctions';

import './UserRegister.scss';

let profilePicChanged = false;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = async (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('Permitido somente JPG ou PNG!');
    return false;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('A imagem precisa ser menor que 2MB!');
    return false;
  }

  return true;
};

let passwordEdit = '';

export default function UserRegister() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [optionsCompanies, setOptionsCompanies] = useState([]);
  const [optionsRoles, setOptionsRoles] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [profilePic, setProfilePic] = useState();
  const [screenModified, setScreenModified] = useState(false);
  const [bitrixUsers, setBitrixUsers] = useState(false);
  const [showCTsField, setShowCTsField] = useState(false);
  const [trainingCenterOptions, setTrainingCenterOptions] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState(optionsCompanies);

  const handleRoleChange = (value, mappedCompanies) => {
    setShowCTsField(false);
    const companiesToFilter = mappedCompanies ?? optionsCompanies;

    let filtered = companiesToFilter;

    switch (value) {
      case 36: // Instrutor
        filtered = companiesToFilter.filter((company) => company.companyType === 'Instrutor');
        break;
      case 37: // Motorista
        filtered = companiesToFilter.filter((company) => company.companyType === 'Motorista');
        break;
      case 38: // Centro de Treinamento
        setShowCTsField(true);
        filtered = companiesToFilter.filter(
          (company) => company.companyType === 'Centro de Treinamento'
        );
        break;
      default:
        // Remove Instrutor, Motorista, e Centro de Treinamento
        filtered = companiesToFilter.filter(
          (company) =>
            !['Instrutor', 'Motorista', 'Centro de Treinamento'].includes(company.companyType)
        );
        break;
    }

    setFilteredCompanies(filtered);
  };

  // Faz o post de usuários
  const submit = async (values) => {
    setLoading(true);
    const data = values;
    if (profilePicChanged) {
      const downloadUrl = await uploadProfilePic(profilePic?.file);
      data.profilePic = downloadUrl;
    }

    const [role] = optionsRoles.filter((element) => element.value === values.role);
    const [company] = optionsCompanies.filter((element) => element.value === values.company);

    data.role = {
      id: values.role,
      name: role.label,
    };

    data.company = {
      id: values.company,
      name: company.label,
      companyType: company.companyType,
    };

    data.notificationTopic = `${company.companyType?.replace(/ /g, '_')}-${company.value}`;

    if (Array.isArray(data.cts)) {
      data.trainingCenterList = data.cts.join(',');
    } else if (data.cts) {
      data.trainingCenterList = String(data.cts);
    }

    if (editId) {
      data.id = parseInt(editId, 10);

      data.password = passwordEdit;
      data.confirmPassword = passwordEdit;
      data.profilePic = imageUrl;

      await api
        .put('/User', data)
        .then(() => {
          message.success('Dados atualizados!');
          setLoading(false);
          setScreenModified(false);
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo deu errado ao editar!');
          setLoading(false);
        });
    } else {
      await api
        .post('/User', data)
        .then(() => {
          message.success('Registro criado!');
          form.resetFields();
          setLoading(false);
          setImageUrl();
          setScreenModified(false);
        })
        .catch((errors) => {
          const [error] = errors.errors;
          if (error.message.includes('Email')) {
            message.error(error.message);
          }
        });
    }
  };

  // Busca empresas para preencher o select
  const fetchCompany = async (roleId) => {
    const companies = await api
      .get('/Company')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as empresas!');
      });

    const mappedCompanies = Object.values(companies).map((item) => ({
      label: item.commercialName ?? item.name,
      value: item.id,
      companyType: item.companyType,
    }));
    setOptionsCompanies(mappedCompanies);

    if (roleId) {
      handleRoleChange(roleId, mappedCompanies);
    }
  };

  // Busca cargos para preencher o select
  const fetchRoles = async () => {
    await api
      .get('/Role')
      .then((res) => {
        setOptionsRoles(
          Object.values(res.data).map((roles) => ({
            label: roles.name,
            value: roles.id,
          }))
        );
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado aos buscar os cargos!');
      });
  };

  const fetchTrainingCenter = async () => {
    const trainingCenters = await api
      .get(
        '/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento'
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os centros de treinamento!');
      });

    const mappedTrainingCenters = trainingCenters?.map(({ id: value, commercialName: label }) => ({
      label,
      value,
    }));

    mappedTrainingCenters?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
    setTrainingCenterOptions(mappedTrainingCenters ?? []);
  };

  // Busca usuário do bitrix para preencher o select
  const fetchBitrixUsers = () =>
    api
      .get(`/Settings?id=1`)
      .then((res) => {
        const decryptedResponse = Utils.decryptSettings(res.data);
        UserFunctions.fetchBitrixUsers(decryptedResponse?.bitrixWebhookUrl, setBitrixUsers);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as configurações do sistema!');
      });

  // Traz os usuários para edição
  const fetchUserEdit = async (id) => {
    setLoading(true);

    const resUser = await api
      .get(`/User?id=${id}`)
      .then((res) => {
        passwordEdit = res.data.password;
        return res.data;
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados!');
      });

    await fetchCompany(resUser.role.id);
    await fetchRoles();
    await fetchBitrixUsers();
    await fetchTrainingCenter();

    const userCTs =
      resUser && resUser.trainingCenterList
        ? resUser.trainingCenterList.split(',').map(Number)
        : [];

    resUser.role = resUser.role.id;
    resUser.companyType = resUser.company.companyType;
    resUser.company = resUser.company.id;

    setImageUrl(resUser?.profilePic);

    form.setFieldsValue({
      ...resUser,
      cts: userCTs,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (editId) {
      fetchUserEdit(editId);
    } else {
      fetchCompany();
      fetchRoles();
      fetchBitrixUsers();
      fetchTrainingCenter();
    }

    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone2'));
    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone1'));
    Inputmask({ mask: '999.999.999-99' }).mask(document.getElementById('cpf'));
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleChange = (info) => {
    profilePicChanged = true;
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };

  return (
    <Form
      form={form}
      disabled={loading}
      name="UserRegister"
      onFinish={submit}
      layout="vertical"
      autoComplete="off"
      onChange={() => setScreenModified(true)}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Button loading={loading} block type="primary" htmlType="submit">
          Salvar
        </Button>
      </div>
      {/* Campos invisiveis */}
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <ImgCrop grid rotate>
              <Upload
                className="user-register-upload"
                accept="image/*"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={(e) => setProfilePic(e)}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    alt=""
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Usuário Bitrix" name="bitrixId">
            <Select
              placeholder="Selecione"
              optionFilterProp="label"
              dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
              options={bitrixUsers}
              showSearch
              onChange={(_value, object) => {
                form.setFieldsValue(object);
                setImageUrl(object.profilePic);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Login"
            name="login"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="exemplo@email.com" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="RG" name="rg">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="CPF"
            name="cpf"
            rules={[
              {
                validator: (rule, value = '') => {
                  const isValid = Utils.validateCPF(value);
                  if (!isValid && value.length > 0) {
                    return Promise.reject(new Error(`CPF Inválido`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input id="cpf" placeholder="000.000.000-00" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Telefone" name="phone1">
            <Input id="phone1" placeholder="(00) 0000-0000" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Celular" name="phone2">
            <Input id="phone2" placeholder="(00) 0000-0000" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Cargo"
            name="role"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select
              options={optionsRoles}
              allowClear
              placeholder="Selecione"
              optionFilterProp="label"
              showSearch
              dropdownStyle={{ borderRadius: 16 }}
              onChange={(val) => handleRoleChange(val)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Empresa"
            name="company"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select
              options={filteredCompanies}
              allowClear
              placeholder="Selecione"
              optionFilterProp="label"
              showSearch
              dropdownStyle={{ borderRadius: 16 }}
            />
          </Form.Item>
        </Col>
        {showCTsField && (
          <Col span={8}>
            <Form.Item
              label="Centros de Treinamento"
              name="cts"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Selecione"
                optionFilterProp="label"
                showSearch
                options={trainingCenterOptions}
                dropdownStyle={{ borderRadius: 16 }}
                maxTagCount="responsive"
                onChange={(values) =>
                  Utils.handleSelectAllChange(values, trainingCenterOptions, 'cts', form)
                }
              />
            </Form.Item>
          </Col>
        )}
        <Col span={4}>
          <Form.Item
            label="Agenda Própria"
            name="hasCalendar"
            valuePropName="checked"
            defaultValue="checked"
            className="form-checkbox"
            style={{
              marginBottom: 0,
              marginTop: 22,
            }}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Aprovador"
            name="isApprover"
            valuePropName="checked"
            defaultValue="checked"
            className="form-checkbox"
            style={{
              marginBottom: 0,
              marginTop: 22,
            }}
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
