import { message } from 'antd';
import axios from 'axios';

import Utils from '../../../Assets/Scripts/Utils';

class UserFunctions {
  static async fetchBitrixUsers(pBitrixUrl, pSetBitrixUsers) {
    try {
      const resultOptions = [];
      const { data: firstResponse } = await axios.get(`${pBitrixUrl}user.get?FILTER[ACTIVE]=true`);

      const totalCount = firstResponse.total;
      const batchCmds = {};
      const batchCount = Math.ceil(totalCount / 50);

      for (let i = 0; i < batchCount; i += 1) {
        const start = i * 50;
        const cmdKey = `cmd${start}`;
        batchCmds[cmdKey] = `user.get?FILTER[ACTIVE]=true&start=${start}&select[]=*`;
      }

      const response = await axios.post(`${pBitrixUrl}batch`, { cmd: batchCmds });

      if (response && response?.data) {
        Object.values(response.data?.result?.result).forEach((result) => {
          result.forEach((user) => {
            resultOptions.push({
              label: `${user.NAME ?? ''} ${user.SECOND_NAME ?? ''} ${user.LAST_NAME ?? ''}`,
              name: `${user.NAME ?? ''} ${user.SECOND_NAME ?? ''} ${user.LAST_NAME ?? ''}`,
              login: user.EMAIL?.split('@')[0],
              email: user.EMAIL,
              value: user.ID,
              phone1: user.WORK_PHONE,
              phone2: user.PERSONAL_MOBILE?.replace('+55', ''),
              profilePic: user.PERSONAL_PHOTO,
            });
          });
        });

        const concatOptions = resultOptions.flat();
        concatOptions.unshift({ value: 'selectAll', label: 'Selecionar todos' });
        pSetBitrixUsers(concatOptions);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao buscar os usuários do Bitrix!');
    }
  }
}

export default UserFunctions;
