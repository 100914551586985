/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Button, Collapse, message, Modal, Upload } from 'antd';
import moment from 'moment';

import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../../Services/axiosService';
import { uploadFilesToFirebase } from '../../TaskList/API/TaskListApi';

import '../../TaskList/Components/ModalStyle.scss';

const { Panel } = Collapse;

function UploadStudentsModal({ setIsOpen, taskList, selectedRowKeys, isUploadStudentsTypeModal }) {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadErrorList, setUploadErrorList] = useState([]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((fileProps) => ({ ...fileProps, status: 'done' }));
    setFileList(newFileList);
  };

  const handleImport = async (file) => {
    if (file.status !== 'removed') {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    }
  };

  const submit = async () => {
    try {
      if (fileList.length > 0 && selectedRowKeys.length > 0) {
        setIsLoading(true);
        let shouldUpdateTasks = false;

        const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));
        const formData = new FormData();

        fileList.forEach((file) => {
          formData.append('files', file.originFileObj);
        });

        selectedTaskList.forEach((task) => {
          formData.append('taskIds', task.id);
        });

        if (isUploadStudentsTypeModal === 'uploadCompany') {
          try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/Task/ImportCompany`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
                Accept: 'application/zip', // Accept header should expect a ZIP file format
              },
              body: formData,
            });

            if (response.status < 200 || response.status >= 300) {
              throw new Error('Falha ao atualizar dados de Empresa');
            }

            if (response.data?.errorList?.length > 0) {
              message.warn('Preenchimento inválido do Arquivo!');
              setUploadErrorList(response.data.errorList);
              setIsLoading(false);
            }

            const blob = await response.blob();
            const fileName = `Certificates_${moment(new Date()).format('YYYY-MM-DD')}.zip`; // Correct filename and extension
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            message.success('Atualização dos Dados de Empresa concluída!');
            window.location.reload(); // Recarrega a página
          } catch (error) {
            message.error('Oops. Algo deu errado ao tentar atualizar os dados de Empresa!');
          }
        } else {
          // Enviar Arquivos contendo Lista de Alunos
          await api
            .post('/Task/ImportStudents', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => {
              if (res.data?.errorList?.length > 0) {
                message.warn('Preenchimento inválido da Lista de Alunos!');
                setUploadErrorList(res.data.errorList);
                setIsLoading(false);
              } else if (res.data?.taskStudentList?.length) {
                shouldUpdateTasks = true;
              }
            });

          if (shouldUpdateTasks) {
            for (let index = 0; index < selectedTaskList.length; index += 1) {
              const newTask = selectedTaskList[index];

              // Realiza Upload dos Arquivos para o Firebase
              const uploadedFiles = await uploadFilesToFirebase(
                fileList,
                'studentList',
                newTask.id
              );

              if (!newTask.studentList) {
                newTask.studentList = [];
              }
              newTask.studentList.push(...uploadedFiles);

              // Atualiza Treinamento
              await api.put('/Task/V2/UpdateTaskScheduling', newTask);

              // Gera Atividade de Upload de Arquivo
              await TaskCommentFunctions.addTaskCommentUploadFile(
                newTask,
                'StudentListUploaded',
                'TaskList'
              );
            }

            message.success('Atualização dos Treinamentos concluída!');
            window.location.reload(); // Recarrega a página
          }
        }
      }
    } catch (error) {
      message.error('Oops. Algo deu errado ao tentar importar a Lista de Alunos!');
      Utils.logError(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={
        isUploadStudentsTypeModal === 'uploadCompany'
          ? 'Importar Dados de Empresa'
          : 'Importar Lista de Alunos'
      }
      open={true}
      width="50vw"
      className="certificate-upload-students-modal"
      closable={!isLoading}
      maskClosable={!isLoading}
      footer={[
        <div className="certificate-upload-students-footer-wrapper">
          <div>
            <Button
              type="primary"
              key="send"
              icon={<DownloadOutlined />}
              onClick={() => submit()}
              disabled={isLoading}
              style={{ backgroundColor: '#5cb85c', color: 'white', border: 'none' }}
            >
              Enviar
            </Button>
            <Button type="ghost" key="cancel" onClick={() => setIsOpen()} disabled={isLoading}>
              Cancelar
            </Button>
          </div>
          <Button
            type="ghost"
            key="download"
            icon={<DownloadOutlined />}
            onClick={() =>
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/modelo-lista-alunos.xlsx?alt=media&token=b1eb23b9-6981-470a-8407-031c246c92bc',
                '_blank'
              )
            }
            disabled={isLoading}
          >
            Baixar Modelo
          </Button>
        </div>,
      ]}
    >
      <Upload
        multiple
        beforeUpload={() => {
          setFileList([]);
        }}
        onChange={handleChange}
        customRequest={({ file }) => handleImport(file)}
        disabled={isLoading}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
      </Upload>

      {uploadErrorList.length > 0 && (
        <Collapse
          defaultActiveKey={['0']}
          expandIconPosition="end"
          style={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          {uploadErrorList.map((fileError, fileIndex) => (
            <Panel
              header={`Erros no Arquivo: ${fileError.fileName}`}
              key={fileIndex}
              className="file-panel"
            >
              {fileError.rowErrorList.map((rowError, rowIndex) => (
                <Collapse key={`row-${rowIndex}`}>
                  <Panel
                    header={`Linha: ${rowError.rowNumber}`}
                    key={`row-${rowIndex}`}
                    className="row-panel"
                  >
                    <div>
                      {rowError.errors.map((error, errorIndex) => (
                        <p key={errorIndex} dangerouslySetInnerHTML={{ __html: error }} />
                      ))}
                    </div>
                  </Panel>
                </Collapse>
              ))}
            </Panel>
          ))}
        </Collapse>
      )}
    </Modal>
  );
}

export default UploadStudentsModal;
