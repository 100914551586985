/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Tag,
  TimePicker,
  Tooltip,
} from 'antd';

import { DeleteOutlined, ForkOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import CustomInstructorSelect from '../../../Components/Schedule/CustomInstructorSelect';
import { getCellInitialValue } from '../Shared/TaskListFunctions';

const { TextArea } = Input;

export default function EditableCellComponent({
  record,
  title,
  inputType,
  dataIndex,
  editable,
  editing,
  attachDeleteEvent,
  children,
  fieldsPermissions,
  formTaskList,
  instructorOptions,
  driverOptions,
  vehicleOptions,
  reasonOptions,
  transportCompanyOptions,
  trainingCenterOptions,
  ...restProps
}) {
  if (!editable) {
    return <td {...restProps}>{children}</td>;
  }

  let fieldName = [dataIndex];
  let initialFieldName = fieldName;
  let additionalRules = null;

  const reasonNoBillingOptions = reasonOptions.filter(({ type }) => type === 'NoBilling');

  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode = <Input placeholder={`Preencha ${title}`} style={{ width: '100%' }} />;
      break;
    case 'textarea':
      inputNode = <TextArea rows={4} placeholder={`Preencha ${title}`} style={{ width: '100%' }} />;
      break;
    case 'email':
      additionalRules = {
        validator: Utils.validateEmails,
      };
      inputNode = (
        <Input placeholder="exemplo@email.com; exemplo2@email.com" style={{ width: '100%' }} />
      );
      break;
    case 'number':
      inputNode = <InputNumber placeholder="0" min={0} style={{ width: '100%' }} />;
      break;
    case 'currency':
      inputNode = (
        <InputNumber
          placeholder={`Preencha ${title}`}
          min={0}
          formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/R\$|\s?|(,*)/g, '')}
          style={{ width: '100%' }}
        />
      );
      break;
    case 'date':
      inputNode = <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />;
      break;
    case 'time':
      inputNode = <TimePicker format="HH:mm" style={{ width: '100%' }} />;
      break;
    case 'dateTime':
      inputNode = <DatePicker showTime format="DD/MM/YYYY HH:mm" style={{ width: '100%' }} />;
      break;
    case 'checkbox':
      inputNode = <Checkbox />;
      break;
    case 'fileUpload':
      inputNode = record[dataIndex]?.map((file, index) => (
        <Tag color="default">
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja deletar o arquivo?"
            onConfirm={async () => {
              const file = record[dataIndex][index];
              file.shouldDelete = true;
              file.deleteEvent = attachDeleteEvent;
              record[dataIndex][index] = file;
            }}
            okText="Sim"
            okType="danger"
            cancelText="Cancelar"
          >
            <a style={{ color: 'red', marginLeft: 8 }}>
              <DeleteOutlined />
            </a>
          </Popconfirm>
          <a href={file.url} target="_blank" rel="noreferrer">
            <span style={{ marginLeft: 8, marginRight: 8 }}>{file.label ?? file.name}</span>
          </a>
        </Tag>
      ));
      break;
    case 'mainInstructor':
      fieldName = [dataIndex, 'userId'];
      initialFieldName = fieldName;
      inputNode = (
        <CustomInstructorSelect
          type={inputType}
          taskData={record}
          options={instructorOptions}
          fieldsPermissions={fieldsPermissions}
        />
      );
      break;
    case 'assistantInstructor':
      fieldName = [dataIndex, 'userId'];
      initialFieldName = fieldName;
      inputNode = (
        <CustomInstructorSelect
          type={inputType}
          taskData={record}
          options={instructorOptions}
          fieldsPermissions={fieldsPermissions}
        />
      );
      break;
    case 'transportCompany':
      fieldName = [dataIndex, 'id'];
      initialFieldName = fieldName;
      inputNode = (
        <Select
          placeholder={`Selecione ${title}`}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
          options={transportCompanyOptions}
          showSearch
          style={{ width: '100%' }}
        />
      );
      break;
    case 'driverList':
      initialFieldName = ['userId'];
      inputNode = (
        <Select
          placeholder={`Selecione ${title}`}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
          options={driverOptions}
          mode="multiple"
          maxTagCount="responsive"
          showSearch
          allowClear
          style={{ width: '100%' }}
        />
      );
      break;
    case 'vehicleList':
      initialFieldName = ['id'];
      inputNode = (
        <Select
          placeholder={`Selecione ${title}`}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
          options={vehicleOptions}
          mode="multiple"
          maxTagCount="responsive"
          showSearch
          allowClear
          style={{ width: '100%' }}
        />
      );
      break;
    case 'reasonNoBilling':
      fieldName = [dataIndex, 'id'];
      initialFieldName = fieldName;
      inputNode = (
        <Select
          placeholder={`Selecione ${title}`}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
          options={reasonNoBillingOptions}
          showSearch
          style={{ width: '100%' }}
        />
      );
      break;
    case 'invoiceIssuer':
      fieldName = [dataIndex, 'id'];
      initialFieldName = fieldName;
      inputNode = (
        <Select
          placeholder={`Selecione ${title}`}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
          options={trainingCenterOptions}
          showSearch
          style={{ width: '100%' }}
        />
      );
      break;
    default:
      inputNode = <strong>INPUT TYPE NÃO DEFINIDO: ({inputType})</strong>;
      break;
  }

  const initialValue = getCellInitialValue(record, dataIndex, initialFieldName, inputType);

  const handleDataPropagation = () => {
    if (inputType !== 'fileUpload') {
      const editingRows = formTaskList.getFieldsValue().list;
      const currentRowData = formTaskList.getFieldsValue().list[record.tablePageIndex][dataIndex];

      for (let index = 0; index < editingRows.length; index += 1) {
        if (editingRows[index]) {
          editingRows[index][dataIndex] = currentRowData;
        }
      }

      formTaskList.setFieldsValue({ list: editingRows });
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <div style={{ display: 'flex' }}>
          <Form.Item
            name={[record.tablePageIndex, ...fieldName]}
            style={{
              margin: 0,
              width: inputType === 'checkbox' || inputType === 'fileUpload' ? 'auto' : '100%',
            }}
            rules={[additionalRules]}
            {...(inputType === 'checkbox' && { valuePropName: 'checked' })}
            {...(initialValue !== null && { initialValue })}
          >
            {inputNode}
          </Form.Item>
          {inputType !== 'fileUpload' && (
            <Tooltip title="Propagar valor para outras linhas selecionadas">
              <ForkOutlined style={{ marginLeft: 5 }} onClick={handleDataPropagation} />
            </Tooltip>
          )}
        </div>
      ) : (
        children
      )}
    </td>
  );
}
