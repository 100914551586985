/* eslint-disable no-bitwise */
// eslint-disable-next-line import/no-cycle
import { cnpj, cpf } from 'cpf-cnpj-validator';
import CryptoJS from 'crypto-js';
import moment from 'moment';

import * as Sentry from '@sentry/react';

export default class Utils {
  // Método para agrupar
  static groupBy(array, key) {
    return array.reduce((acc, item) => {
      if (!acc[item[key]]) acc[item[key]] = [];
      acc[item[key]].push(item);
      return acc;
    }, {});
  }

  // Pegar cookies
  static getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  // Deletar cookies
  static delCookies() {
    // Remove os cookies
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    // Remove Storage
    localStorage.removeItem('conecta__token');
    localStorage.removeItem('conecta__refreshToken');
    localStorage.removeItem('conecta__permissions');
    localStorage.removeItem('conecta__userData');
    localStorage.removeItem('conecta__scheduleData');
    localStorage.removeItem('conecta__currentView');
    localStorage.removeItem('conecta__menuPreference');

    // Redireciona para Login
    window.location.href = '/Login';
  }

  // Verificar se existe cookie para manter logado
  static hasCookies() {
    const refreshTokenCookie = Utils.getCookie('conecta__refreshToken');
    const token = localStorage.getItem('conecta__token');

    if (refreshTokenCookie) {
      // O usuário escolheu permanecer conectado usando cookies
      return true;
    }
    if (!refreshTokenCookie && token) {
      // O usuário optou por não permanecer conectado, limpar o armazenamento local
      localStorage.removeItem('conecta__token');
      localStorage.removeItem('conecta__permissions');
      localStorage.removeItem('conecta__userData');
      localStorage.removeItem('conecta__scheduleData');
      localStorage.removeItem('conecta__currentView');
      localStorage.removeItem('conecta__menuPreference');
      return false;
    }

    // Limpar cookies se ambos os cookies e o token estiverem ausentes
    this.delCookies();
    return false;
  }

  // Gera UUID
  static generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  }

  // Valida CNPJ
  static validateCNPJ(cnpjValue) {
    let data = cnpjValue;
    data = cnpjValue?.replace(/[^\d]+/g, '');

    const isValid = cnpj?.isValid(data);
    return isValid;
  }

  // Valida CPF
  static validateCPF(cpfValue) {
    let data = cpfValue;
    data = cpfValue?.replace(/[^\d]+/g, '');

    const isValid = cpf?.isValid(data);
    return isValid;
  }

  // Converte cor HEX para RGBA
  static hexToRgbA(hex, opacity = 1) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');

      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;

      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }
    throw new Error('Código Hex inválido!');
  }

  static validateEmails(_, value) {
    if (!value || value === '') {
      return Promise.resolve(); // Considera campo vazio como válido
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emails = value.split(';').map((email) => email.trim());

    if (emails.some((email) => !emailRegex.test(email))) {
      return Promise.reject(
        new Error('Por favor, insira um ou mais e-mails válidos separados por ponto e vírgula (;).')
      );
    }

    if (/[,/:]/.test(value)) {
      return Promise.reject(
        new Error('Utilize apenas ponto e vírgula (;) para separar os e-mails.')
      );
    }

    return Promise.resolve();
  }

  // Converte cor Hex para RGB (Utilizado pela função "getColorLightness")
  static colorCodeToRGB(colorCode) {
    let color = colorCode;
    color = color.substr(1);
    return [color.substr(0, 2), color.substr(2, 2), color.substr(4, 2)].map((it) =>
      parseInt(it, 16)
    );
  }

  // Lineariza o RGB
  static linearizeSRGB(colorChannel) {
    const color = colorChannel;

    if (color <= 0.04045) {
      return color / 12.92;
    }
    return ((colorChannel + 0.055) / 1.055) ** 2.4;
  }

  // Pega o brilho da cor
  static getColorLightness(color) {
    const luminanceCoefficients = [0.2126, 0.0722, 0.7152];
    const cv = this.colorCodeToRGB(color).map((colorRGB) => this.linearizeSRGB(colorRGB));

    const luminance =
      cv[0] * luminanceCoefficients[0] +
      cv[1] * luminanceCoefficients[1] +
      cv[2] * luminanceCoefficients[2];

    if (luminance <= 216 / 24389) {
      return luminance * (24389 / 27);
    }
    return (luminance ** (1 / 3) * 116 - 16) / 100;
  }

  static getWeekDayLong(date) {
    const dateFormatted = new Date(date);
    let weekDay = dateFormatted.toLocaleString('pt-br', { weekday: 'long' });

    if (dateFormatted.getDay() === 0 || dateFormatted.getDay() === 6) {
      weekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
    } else {
      const weekDaySplitted = weekDay.split('-');
      weekDay = weekDaySplitted[0].charAt(0).toUpperCase() + weekDaySplitted[0].slice(1);
    }

    return weekDay;
  }

  static getMonthShort(date) {
    const dateFormatted = new Date(date);
    let weekDay = dateFormatted.toLocaleString('pt-br', { month: 'short' });
    weekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
    return weekDay.replace('.', '');
  }

  static getMonthLong(date) {
    const dateFormatted = new Date(date);
    let weekDay = dateFormatted.toLocaleString('pt-br', { month: 'long' });
    weekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
    return weekDay.replace('.', '');
  }

  static createSafeDate(dateInput, slotTime) {
    const date = dateInput;
    const slotDate = slotTime;

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const hours = slotDate.getHours();
    const minutes = slotDate.getMinutes();
    const seconds = slotDate.getSeconds();

    let safeDate = moment([year, month, day, hours, minutes, seconds]);

    if (!safeDate.isValid()) {
      safeDate = moment([year, month]).endOf('month');
    }

    return safeDate.toDate();
  }

  // Função para descriptografar a resposta da API
  static decryptData(base64Data) {
    const base64Pattern = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if (!base64Pattern.test(base64Data)) {
      return base64Data;
    }

    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPT_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPT_IV);

    const decryptedData = CryptoJS.AES.decrypt(base64Data, key, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv,
    });

    const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

    if (!decryptedText) {
      return null;
    }

    try {
      return JSON.parse(decryptedText);
    } catch (error) {
      return decryptedText;
    }
  }

  // Função para descriptografar campos sensíveis
  static decryptSettings(settings) {
    const sensitiveFields = [
      'emailPassword',
      'bitrixWebhookUrl',
      'bitrix24QueueMiddlewareAuthToken',
      'holidayApiToken',
    ];

    const decryptedSettings = { ...settings };
    sensitiveFields.forEach((field) => {
      if (decryptedSettings[field]) {
        decryptedSettings[field] = this.decryptData(decryptedSettings[field]);
      }
    });

    return decryptedSettings;
  }

  static logError(error) {
    this.sendErrorToAPI(error);

    if (process.env.NODE_ENV === 'production') {
      const isUnauthorizedError = error?.response && error?.response?.status === 401;
      const errorMessage = error?.response?.data?.message;

      const messagesToIgnore = [
        'Email já cadastrado',
        'Usuário não encontrado',
        "(reading 'removeDroppableClasses')",
      ];

      const shouldLogError = !messagesToIgnore.includes(errorMessage);

      if (!isUnauthorizedError && shouldLogError && error) {
        Sentry.captureException(error);
      }
    } else {
      console.error('Erro:', error);
    }
  }

  static sendErrorToAPI(error) {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    const logModel = {
      UserId: userId,
      Action: error.config?.method?.toUpperCase() ?? 'NONE',
      ActionName: 'Frontend',
      Date: new Date().toISOString(),
      ObjectType: error.constructor?.name,
      ErrorMessage: error.response?.data?.message || error.message,
      ErrorTrace: error.stack,
      ErrorRequestMethod: error.config?.method?.toUpperCase(),
      ErrorRequestPath: error.config?.url,
      ErrorRequestQuery: error.config?.params,
      LogType: 'ERROR',
    };

    const endpoint = `${process.env.REACT_APP_HOST}/Log/GenerateFileLog?ExternalKey=${process.env.REACT_APP_API_EXTERNAL_KEY}`;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logModel),
    });
  }

  static handleSelectAllChange(values, options, fieldName, form) {
    if (values.includes('selectAll') || values.length === (options?.length ?? 0 - 1)) {
      // eslint-disable-next-line no-param-reassign
      values = options?.map((opt) => opt.value).filter((opt) => opt !== 'selectAll');
      form.setFieldValue(fieldName, values);
      if (values.length === (options?.length ?? 0 - 1)) {
        values.push('selectAll');
      }
    }
  }
}
