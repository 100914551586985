/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Skeleton, Tooltip } from 'antd';
import { Accordion, ScrollView } from 'devextreme-react';
import moment from 'moment';

import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import QuoteListFunctions from './QuoteListFunctions';
import Task from './Task';

import './QuoteList.scss';

function QuoteList({
  loading,
  stages: stagesParam,
  trainings: trainingsParam,
  schedulerRef,
  dealId,
  bitrixUsers,
  fetchQuotes,
  quoteList,
  setQuoteList,
  trainingCenterOptions,
  fetchInstructors,
  fetchSlots,
  callSlotGeneration,
  fetchTasks,
  setTrainingCenter,
  taskRescheduling,
  exitReschedulingMode,
}) {
  const [form] = Form.useForm();

  const [stageOptions, setStageOptions] = useState();
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [loader, setLoader] = useState(false);

  const userData = JSON.parse(localStorage.getItem('conecta__userData'));

  const filterQuotesList = (global, stages, responsibles, trainings, pQuoteList) => {
    const searchTerm = global
      ?.normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toUpperCase(); // Limpa e Padroniza a string

    const quotesFiltered = [];

    const stagesToFilter = stages;
    const responsiblesToFilter = responsibles;
    const trainingsToFilter = trainings;

    pQuoteList.forEach((quote) => {
      const tasksFiltered = quote.tasks?.filter(
        // eslint-disable-next-line array-callback-return, consistent-return
        (task) => {
          let taskTextConcat = '';
          taskTextConcat += task.classId;
          taskTextConcat += ` ${task.cardTitle}`;
          taskTextConcat += ` ${task.text}`;
          taskTextConcat += ` Alunos: ${task.qtyStudentsPerClass}`;
          taskTextConcat += ` ${task.stage.name}`;
          taskTextConcat += ` ${task.startDateFormatted ?? ''}`;
          taskTextConcat += ` ${task.dealTitle}`;
          taskTextConcat += ` ${task.dealClient}`;
          taskTextConcat += ` ${task.dealClientCNPJ}`;
          taskTextConcat += ` ${task.trainingType}`;
          taskTextConcat += ` ${task.dealClientCommercialName}`;
          taskTextConcat += ` ${task.dealId}`;
          taskTextConcat = taskTextConcat
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .toUpperCase(); // Limpa e Padroniza a string

          let validTaskText = taskTextConcat.includes(searchTerm);
          let validTaskStage = stagesToFilter?.some((stage) => stage === task.stage.id);
          let validTaskResponsible = responsiblesToFilter?.some(
            (responsible) => responsible === task.responsible
          );
          let validTaskTraining = trainingsToFilter?.some((training) => training === task.group.id);

          if (!global || searchTerm === '') {
            validTaskText = true;
          }

          if (!stagesToFilter || stagesToFilter.length === 0) {
            validTaskStage = true;
          }

          if (!trainingsToFilter || trainingsToFilter.length === 0) {
            validTaskTraining = true;
          }

          if (!responsiblesToFilter || responsiblesToFilter.length === 0) {
            validTaskResponsible = true;
          }

          if (validTaskText && validTaskStage && validTaskTraining && validTaskResponsible) {
            return task;
          }
        }
      );

      if (tasksFiltered.length > 0) {
        quotesFiltered.push({ ...quote, tasks: tasksFiltered });
      }
    });

    setQuoteList(quotesFiltered);
    setLoader(false);
  };

  const fetchQuoteList = async ({ global, stages, responsibles, trainings }) => {
    try {
      setLoader(true);
      const maxComplexity = 30;

      const filters = [];

      // Filtro Estágios
      if (stages?.length > 0) {
        filters.push(
          `filters[${filters.length}].Field=Stage.Id&filters[${
            filters.length
          }].Condition=NUMBER.IN&filters[${filters.length}].Value=${stages.join(',')}`
        );
      }

      let tasks = [];

      // Calcula a quantidade de requisições necessárias baseado na complexidade máxima do firebase
      let numRequests = 1;
      if (responsibles?.length > 0) {
        const totalResponsibles = responsibles.length;
        const maxResponsiblesPerRequest = Math.floor(maxComplexity / stages.length);
        numRequests = Math.ceil(totalResponsibles / maxResponsiblesPerRequest);

        for (let i = 0; i < numRequests; i += 1) {
          const startIdx = i * Math.ceil(responsibles.length / numRequests);
          const endIdx = (i + 1) * Math.ceil(responsibles.length / numRequests);
          const chunkResponsibles = responsibles.slice(startIdx, endIdx);

          // Filtro Responsáveis
          if (chunkResponsibles?.length > 0) {
            filters.push(
              `filters[${filters.length}].Field=Responsible&filters[${
                filters.length
              }].Condition=IN&filters[${filters.length}].Value=${chunkResponsibles.join(',')}`
            );
          }

          const filterString = filters.join('&');

          const tasksResponse = await api.get(`/Task?${filterString}`);
          tasks = [...tasks, ...tasksResponse.data];
          filters.pop();
        }
      }

      const dealIds = [...new Set(tasks.map(({ dealId }) => dealId))];

      let quotesResponse = [];
      if (dealIds?.length > 0) {
        quotesResponse = await fetchQuotes(dealIds);
      }

      const quoteList = quotesResponse?.map((quote) => {
        const quoteTasks = tasks
          .filter((task) => task.dealId === quote.dealId)
          .map((task) => {
            // Calcula a Duração do Treinamento
            let durationHours = 0;
            if (task.startDate && task.startHour && task.endHour) {
              const durationMinutes = moment(new Date(task.endHour)).diff(
                new Date(task.startHour),
                'minutes'
              );
              durationHours = durationMinutes / 60;
            }

            const formattedStartDate = task.startDate
              ? moment(task.startDate).format('DD/MM/YYYY')
              : null;

            const taskMapped = {
              ...quote,
              ...task,
              quoteName: quote.quoteTitle,
              text: task.product.name,
              durationHours,
              startDateFormatted: formattedStartDate,
              showTransport: task.showTransport,
            };

            return taskMapped;
          });

        return { id: quote.dealId, name: quote.quoteTitle, tasks: quoteTasks };
      });

      filterQuotesList(global, stages, responsibles, trainings, quoteList);
      return quoteList;
    } catch (error) {
      setLoader(false);
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar a Lista de Treinamentos!');
      return [];
    }
  };

  const handleChange = async (value) => {
    try {
      const trainingCenter = trainingCenterOptions.find(({ id }) => id === value);
      if (!trainingCenter) {
        message.error('Centro de treinamento não encontrado');
        return;
      }
      const ctData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

      const newCtData = {
        trainingCenterId: trainingCenter.id,
        trainingCenter: trainingCenter.commercialName,
        date: ctData.date,
        viewType: ctData.viewType,
        maxCapacityPerDay: trainingCenter.maxCapacityPerDay,
      };

      localStorage.setItem('conecta__scheduleData', JSON.stringify(newCtData));

      setTrainingCenter(trainingCenter);
      await fetchInstructors();
      const slotsToFunction = await fetchSlots(trainingCenter);
      callSlotGeneration(trainingCenter, null, null, null, slotsToFunction);

      await fetchTasks(
        trainingCenter,
        schedulerRef.current.instance.getStartViewDate(),
        schedulerRef.current.instance.getEndViewDate()
      );
      form.submit();
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao filtrar o Centro de Treinamento!');
    }
  };

  const renderQuote = (itemData) => (
    <Task itemData={itemData} schedulerRef={schedulerRef} taskRescheduling={taskRescheduling} />
  );

  const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));
  const initialCtId = scheduleData ? scheduleData.trainingCenterId : undefined;

  useEffect(() => {
    const stages = stagesParam?.map(({ id, name }) => ({ label: name, value: id }));
    stages?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
    setStageOptions(stages);

    const trainings = trainingsParam?.map(({ id, name }) => ({ label: name, value: id }));
    trainings?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
    setTrainingsOptions(trainings);
  }, []);

  useEffect(() => {
    form.setFieldValue('global', dealId);
    form.submit();
  }, []);

  useEffect(() => {
    QuoteListFunctions.calculateQuoteListTotal(quoteList);
  }, [quoteList]);

  return (
    <>
      <Form
        form={form}
        disabled={loading || loader}
        name="QuotesFilter"
        onFinish={fetchQuoteList}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          responsibles: userData?.bitrixId ? [userData.bitrixId] : [],
          stages: stagesParam?.filter(({ id }) => ![3, 12].includes(id))?.map(({ id }) => id),
          trainingCenterId: initialCtId,
        }}
      >
        <Row gutter={[24]}>
          <Col span={5}>
            <Form.Item label="CT" name="trainingCenterId">
              <Select
                options={trainingCenterOptions.map((tc) => ({
                  label: tc.commercialName,
                  value: tc.id,
                }))}
                loading={loading}
                onChange={handleChange}
                placeholder="Selecione um CT"
                optionFilterProp="label"
                dropdownStyle={{ borderRadius: 16 }}
              />
            </Form.Item>
          </Col>
          {taskRescheduling && (
            <Col span={19}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'rgba(177, 156, 217, 0.2)',
                  padding: '5px',
                  textAlign: 'center',
                  borderRadius: '10px 16px 16px 10px',
                  marginTop: '22px',
                }}
              >
                <span style={{ flex: 2, textAlign: 'center' }}>Modo de Reagendamento</span>
                <Button danger type="text" disabled={loading} onClick={exitReschedulingMode}>
                  Cancelar
                </Button>
              </div>
            </Col>
          )}
          {!taskRescheduling && (
            <>
              <Col span={5}>
                <Form.Item label="Global" className="tooltip-globalfilter">
                  <Tooltip
                    placement="right"
                    title="É possível filtrar por: Turma, Treinamento, Qtd. Alunos, Tipo de Treinamento, Estágio, Data (DD/MM/AAAA), Número do Negócio, Nome do Negócio, Nome do Cliente e CNPJ."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Form.Item>
                <Form.Item name="global">
                  <Input placeholder="Filtro" prefix={<SearchOutlined />} allowClear />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Responsável" name="responsibles">
                  <Select
                    options={bitrixUsers}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    removeIcon={false}
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(values) =>
                      Utils.handleSelectAllChange(values, bitrixUsers, 'responsibles', form)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Estágios" name="stages">
                  <Select
                    options={stageOptions}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    removeIcon={false}
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(values) => {
                      Utils.handleSelectAllChange(values, stageOptions, 'stages', form);
                      if (values.length >= 30) {
                        message.warning('Somente 30 estágios podem ser selecionados.');
                        form.setFieldValue('stages', []);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Treinamentos" name="trainings">
                  <Select
                    options={trainingsOptions}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    removeIcon={false}
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(values) =>
                      Utils.handleSelectAllChange(values, trainingsOptions, 'trainings', form)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Button type="primary" htmlType="submit">
                  <SearchOutlined />
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {(loading || loader) && (
        <div className="quote-list-skeleton">
          <Skeleton.Input active block size="large" className="quote-list-skeleton-input" />
          <Skeleton.Input active block size="large" className="quote-list-skeleton-input" />
          <Skeleton.Input active block size="large" className="quote-list-skeleton-input" />
        </div>
      )}

      {!loading && !loader && (
        <ScrollView id="scrollQuotes">
          <Accordion
            id="quotesList"
            disabled={loading}
            dataSource={taskRescheduling ?? quoteList}
            collapsible
            multiple
            itemTitleRender={(quote) => (
              <h2>
                {quote.trainingsIndicator} {quote.id} - {quote.name}
              </h2>
            )}
            itemRender={renderQuote}
          />
        </ScrollView>
      )}
    </>
  );
}

export default React.memo(QuoteList);
