/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Card, Timeline } from 'antd';
import moment from 'moment';

import { ExportOutlined, UserOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';

import './TaskComment.scss';

export default function TaskComment({ comments }) {
  const formattedComments = comments?.map((item) => {
    let prettyDate = '';
    const daysDiff = moment(new Date()).diff(new Date(moment(item.createDate)), 'days');

    if (daysDiff === 0) {
      prettyDate = `Hoje`;
    } else if (daysDiff === 1) {
      prettyDate = `Ontem`;
    } else if (daysDiff < 7) {
      prettyDate = Utils.getWeekDayLong(item.createDate);
    } else {
      prettyDate = `${new Date(item.createDate).getDate()} ${Utils.getMonthLong(item.createDate)}`;
    }

    return {
      ...item,
      prettyDate,
    };
  });

  const commentsGroupedByDate = Utils.groupBy(formattedComments, 'prettyDate');

  if (comments) {
    return (
      <Timeline id="task-activities" reverse>
        {Object.keys(commentsGroupedByDate).map((prettyDate) => (
          <React.Fragment key={prettyDate}>
            <div className="task-activity-date-wrapper">
              <p className="task-activity-date-tag">{prettyDate}</p>
            </div>
            {commentsGroupedByDate[prettyDate]
              .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
              .map(
                ({
                  id,
                  title,
                  description,
                  reasonId,
                  reason,
                  location,
                  createDate,
                  percentageToBeCharged,
                  userName,
                  userAvatar,
                  urlRedirect,
                }) => (
                  <Timeline.Item key={id}>
                    <Card>
                      {/* Header */}
                      <div className="task-activity-header">
                        <p className="task-activity-title-wrapper">
                          <span>{title}</span> {moment(createDate).format('HH:mm')}
                        </p>

                        <span>
                          {urlRedirect && (
                            <a
                              href={urlRedirect}
                              target="_blank"
                              rel="noreferrer"
                              title="Ir para Cadastro Instrutor"
                              style={{ marginRight: 5 }}
                            >
                              <ExportOutlined />
                            </a>
                          )}
                          <Avatar
                            style={{ width: '22px', height: '22px' }}
                            src={userAvatar}
                            icon={!userAvatar && <UserOutlined />}
                          />
                        </span>
                      </div>

                      {/* Body */}
                      {userName && <p className="task-comment-username">{userName}</p>}
                      {reasonId && !location && (
                        <>
                          <p>{reason}</p>
                          {percentageToBeCharged && (
                            <p>Percentual a ser cobrado: {percentageToBeCharged}%</p>
                          )}
                          {description && (
                            <p dangerouslySetInnerHTML={{ __html: `${description}` }} />
                          )}
                        </>
                      )}

                      {!reasonId && location && (
                        <>
                          <p>Local: {location.name}</p>
                          <p>Geolocalização: {location.geopoint}</p>
                        </>
                      )}

                      {!reasonId && !location && (
                        <>
                          {description && (
                            <p dangerouslySetInnerHTML={{ __html: `${description}` }} />
                          )}
                        </>
                      )}
                    </Card>
                  </Timeline.Item>
                )
              )}
          </React.Fragment>
        ))}
      </Timeline>
    );
  }
  return '';
}
